<!--
 * @Author: mulingyuer
 * @Date: 2021-05-25 14:58:28
 * @LastEditTime: 2021-05-26 15:49:45
 * @LastEditors: mulingyuer
 * @Description: 专题-新增&编辑
 * @FilePath: \saas-admin-vue\src\modules\portal\views\article-subject\add-edit.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="add-edit">
    <div class="content" v-loading="loading">
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        size="medium"
        class="medium-form"
      >
        <el-form-item label="名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入专题名称"></el-input>
        </el-form-item>
        <el-form-item label="专题分类：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择专题分类">
            <el-option
              v-for="op in categoryArr"
              :key="op.id"
              :label="op.name"
              :value="op.id"
            ></el-option>
          </el-select>
          <!-- 通用添加分类组件 -->
          <AddCategoryButton
            :style="{
              marginLeft: '12px',
              display: 'inline-block'
            }"
            :updateList="refreshCategories"
            :getApi="getApi"
            :form="categoryForm"
          >
          </AddCategoryButton>
        </el-form-item>
        <el-form-item label="简介：" prop="description" class="description">
          <el-input type="textarea" v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item label="栏目：" prop="columns">
          <el-checkbox-group
            v-show="false"
            v-model="form.columns"
          ></el-checkbox-group>
          <div class="columns-warp" v-loading="columnLoading">
            <div class="columns-head">
              <el-button
                type="primary"
                size="small"
                @click="openAddColumnDialog = true"
                >添加</el-button
              >
            </div>
            <div class="columns-body">
              <VueDraggable v-model="form.columns" animation="200">
                <div
                  class="columns-item"
                  v-for="(item, index) in form.columns"
                  :key="item.id"
                >
                  <span class="val">{{ item.name | placeholder }}</span>
                  <i
                    class="el-icon-edit btn"
                    @click="onColumnsEdit(item, index)"
                  ></i>
                  <i
                    class="el-icon-error btn"
                    @click="onColumnsDelete(item, index)"
                  ></i>
                </div>
              </VueDraggable>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="列表图：" prop="list_image">
          <el-input v-show="false" v-model="form.list_image"></el-input>
          <SingleMediaWall
            v-model="form.list_image"
            :width="240"
            :height="120"
            :show-cut="true"
            :ratio="ratioList"
            :borderRadius="4"
          >
            <template #info>
              <div class="info">
                <i class="el-icon-info"></i>推荐尺寸：
<!--                <p style="padding-left: 20px">-->
<!--                  模板一（横向滑动卡片）尺寸162*111；-->
<!--                </p>-->
<!--                <p style="padding-left: 20px">-->
<!--                  模板二（竖向列表，图片在左侧）尺寸146*86；-->
<!--                </p>-->
<!--                <p style="padding-left: 20px">模板三（横向滑动）尺寸143*80；</p>-->
                <p :key="r.ratio" style="padding-left: 20px" v-for="r in ratioList">{{r.desc}}；</p>
              </div>
            </template>
          </SingleMediaWall>
        </el-form-item>
        <el-form-item label="详情图：" prop="image">
          <el-input v-show="false" v-model="form.image"></el-input>
          <SingleMediaWall
            v-model="form.image"
            :width="240"
            :height="120"
            :show-cut="true"
            ratio="750:286"
            :borderRadius="4"
          >
            <template #info>
              <div class="info">
                <i class="el-icon-info"></i>{{`建议尺寸为${750 / 2 * 3}*${286 / 2 * 3}`}}
              </div>
            </template>
          </SingleMediaWall>
        </el-form-item>
        <el-form-item label="分享图：" prop="share_image">
          <el-input v-show="false" v-model="form.share_image"></el-input>
          <SingleMediaWall
            v-model="form.share_image"
            :width="240"
            :height="120"
            :show-cut="true"
            ratio="5:4"
            :borderRadius="4"
          >
            <template #info>
              <div class="info">
                <i class="el-icon-info"></i>{{`建议尺寸为500*400`}}
              </div>
            </template>
          </SingleMediaWall>
        </el-form-item>
      </el-form>
    </div>
    <!-- 悬浮按钮 -->
    <fixed-action-bar>
      <el-button
        v-if="!loading"
        type="primary"
        size="medium"
        :loading="saveLoading"
        @click="onSave('ruleForm')"
        >保存</el-button
      >
      <el-button size="medium" @click="onCancel">取消</el-button>
    </fixed-action-bar>
    <!-- 新增栏目弹窗 -->
    <AddColumnDialog v-model="openAddColumnDialog" :complete="onAddColumn" />
  </div>
</template>

<script>
import SingleMediaWall from '@/modules/common/components/SingleMediaWall'
import FixedActionBar from '@/base/layout/FixedActionBar'
import VueDraggable from 'vuedraggable'
import AddColumnDialog from '../../components/article-subject/add-edit/AddColumnDialog'
import { subjectCategory } from '../../api/article-subject/index'
import {
  addColumn,
  deleteColumn,
  subjectDetail,
  subjectSave,
} from '../../api/article-subject/add-edit'
import AddCategoryButton from '@/base/components/Base/AddCategoryButton.vue'
import { addClassify } from '../../api/classify-list'

export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        id: 0, //为0时新增
        name: '', //名称
        category_id: '', //专题分类
        description: '', //简介
        columns: [], //栏目
        list_image: '',
        image: '', // 封面图
        share_image: '', // 分享图
      },
      rules: {
        name: [{ required: true, message: '请输入专题名称', trigger: 'blur' }],
        description: [
          { required: true, message: '请输入简介', trigger: 'blur' },
        ],
        category_id: [
          { required: true, message: '请选择专题分类', trigger: 'change' },
        ],
        columns: [
          {
            type: 'array',
            required: true,
            message: '请至少添加一个栏目',
            trigger: 'change',
          },
        ],
        list_image: [
          { required: true, message: '请上传列表图', trigger: 'change' },
        ],
        image: [{ required: true, message: '请上传详情图', trigger: 'change' }],
        share_image: [
          { required: true, message: '请上传分享图', trigger: 'change' },
        ],
      },
      editData: {}, //编辑的数据
      categoryArr: [], //分类选项数据
      saveLoading: false, //保存中
      columnLoading: false, //栏目操作中
      openAddColumnDialog: false, //是否显示新增栏目弹窗

      categoryForm: {
        name: '',
        sort: '',
        pid: '',
      },

      ratioList: [
        {
          desc: `模板一（横向滑动卡片）尺寸${162 * 3}*${111 * 3}`,
          ratio: `${162 * 3}:${111 * 3}`,
        },
        {
          desc: `模板二（竖向列表，图片在左侧）尺寸${146 * 3}*${86 * 3}`,
          ratio: `${146 * 3}:${86 * 3}`,
        },
        {
          desc: `模板三（横向滑动）尺寸${143 * 3}*${80 * 3}`,
          ratio: `${143 * 3}:${80 * 3}`,
        },
      ]
    }
  },
  computed: {
    //编辑的专题id
    editId() {
      return this.$route.query.id
    },
  },
  methods: {
    getApi(data) {
      return addClassify(data)
    },
    refreshCategories({name}) {
      this.getSubjectCategory(name)
    },
    //获取分类选项数据
    getSubjectCategory(name = '') {
      return subjectCategory()
        .then((res) => {
          const { data } = res
          this.categoryArr = data
          if (name) {
            const exist = this.categoryArr.find(c => c.name === name)
            if (exist?.id) {
              this.form.category_id = exist.id // 新增后自动选
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    //获取编辑的专题详情
    getSubjectDetail() {
      const postData = { id: this.editId }
      return subjectDetail(postData)
        .then((res) => {
          const { data } = res
          this.editData = data
          if (data.list_image === null) data.list_image = ''
          this.initForm()
        })
        .catch(() => {
          this.loading = false
        })
    },
    //初始化表单
    initForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.editData[key]
      })
    },
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          const postData = { ...this.form }
          subjectSave(postData)
            .then((res) => {
              const { msg } = res
              this.$message.success(msg)
              this.saveLoading = false
              this.resetForm(formName)
              this.$router.push({ name: 'ArticleSubjectList' })
            })
            .catch(() => {
              this.saveLoading = false
            })
        }
      })
    },
    //取消
    onCancel() {
      this.$router.push({ name: 'ArticleSubjectList' })
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    //栏目添加事件
    onAddColumn(postData) {
      this.columnLoading = true
      addColumn(postData)
        .then((res) => {
          const { data, msg } = res
          this.$message.success(msg)
          this.form.columns.push({ id: data, name: postData.name })
          this.columnLoading = false
        })
        .catch(() => {})
    },
    //栏目删除事件
    onColumnsDelete(id, index) {
      this.columnLoading = true
      const postData = { id }
      deleteColumn(postData)
        .then((res) => {
          const { msg } = res
          this.$message.success(msg)
          this.form.columns.splice(index, 1)
          this.columnLoading = false
        })
        .catch(() => {
          this.columnLoading = false
        })
    },
    //栏目编辑事件
    onColumnsEdit(item, index) {
      this.$prompt('请输入新的栏目名称', '修改', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.name,
      }).then(({ value }) => {
        item.name = value
        console.log(value)
      })
    },
  },
  async created() {
    try {
      this.loading = true
      await this.getSubjectCategory()
      if (typeof this.editId !== 'undefined') {
        await this.getSubjectDetail()
      }
      this.loading = false
    } catch (err) {
      this.$message.error(err.message)
      this.loading = false
    }
  },
  //路由进入前
  beforeRouteEnter(to, from, next) {
    const id = to.query.id
    if (typeof id !== 'undefined') {
      to.meta.title = '编辑'
    } else {
      to.meta.title = '新增'
    }
    next()
  },
  components: {
    SingleMediaWall,
    FixedActionBar,
    VueDraggable,
    AddColumnDialog,
    AddCategoryButton,
  },
}
</script>

<style lang="scss" scoped>
.add-edit {
  .content {
    padding: 20px 0;
    .medium-form {
      .description {
        .el-textarea {
          height: 180px;
        }
      }
      .columns-warp {
        max-width: 520px;
        .columns-head {
          margin-bottom: 10px;
        }
        .columns-body {
          .columns-item {
            display: flex;
            align-items: center;
            padding: 10px 8px;
            color: #333;
            background-color: #f2f2f2;
            cursor: move;
            & + .columns-item {
              margin-top: 10px;
            }

            .val {
              min-width: 0;
              flex-grow: 1;
              @include nowrap();
            }
            .btn {
              flex-shrink: 0;
              padding: 5px;
              font-size: 20px;
              color: #666;
              cursor: pointer;
              &:hover {
                color: #3576ff;
              }
              &:active {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
}
</style>
