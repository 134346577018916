/*
 * @Author: mulingyuer
 * @Date: 2021-05-25 15:52:50
 * @LastEditTime: 2021-05-25 17:25:08
 * @LastEditors: mulingyuer
 * @Description:专题-新增&编辑
 * @FilePath: \saas-admin-vue\src\modules\portal\api\article-subject\add-edit.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

//保存
export const subjectSave = (data) => {
  return api({
    url: "/admin/portal/subject/save",
    method: "post",
    data
  })
};


//新增栏目
export const addColumn = (data) => {
  return api({
    url: "/admin/portal/subject/saveColumn",
    method: "post",
    data
  })
};

//删除栏目
export const deleteColumn = (data) => {
  return api({
    url: "/admin/portal/subject/delColumn",
    method: "post",
    data
  })
};

//专题详情
export const subjectDetail = (data) => {
  return api({
    url: "/admin/portal/subject/detail",
    method: "post",
    data
  })
};