<!--
 * @Author: mulingyuer
 * @Date: 2021-05-25 17:07:09
 * @LastEditTime: 2021-05-25 17:18:27
 * @LastEditors: mulingyuer
 * @Description: 添加栏目弹窗
 * @FilePath: \saas-admin-vue\src\modules\portal\components\article-subject\add-edit\AddColumnDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="添加栏目" :visible.sync="show" width="500px" @close="onClose('ruleForm')"
    class="dialog-vertical add-column-dialog">
    <div class="content">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="medium-form">
        <el-form-item label="栏目名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入栏目名称"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button type="primary" size="small" @click="onConfirm('ruleForm')">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //确认后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        name: "", //栏目名称
      },
      rules: {
        name: [{ required: true, message: "请输入栏目名称", trigger: "blur" }],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //关闭弹窗回调
    onClose(formName) {
      this.resetForm(formName);
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //确认
    onConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.complete({ id: 0, name: this.form.name });
          this.show = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
